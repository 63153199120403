<template>
    <v-container>
        <NavBar :step="4" />
        <Titulo></Titulo>

        <br>
        <br>
        <br>
        <v-row justify="center">
            <v-dialog v-model="dialog" width="600px">
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Términos y Condiciones</span>
                    </v-card-title>
                    <v-card-text style="text-align: justify;">
                        Por este acto, y según lo dispuesto en la Ley N° 19.628 sobre Protección de la Vida Privada y
                        sus modificaciones, autorizo expresamente a Southbridge Compañía de Seguros Generales S.A. para
                        que haga tratamiento, almacene, transmita y comunique mis datos personales y la información que
                        le he proporcionado voluntariamente, a su matriz, filiales, sociedades coligadas, empresas
                        relacionadas, sociedades del Grupo Empresarial al que pertenece y terceros prestadores de
                        servicios, estén ubicados dentro o fuera de Chile, con el propósito de que tanto Southbridge
                        Compañía de Seguros Generales S.A. como dichas entidades i) me contacten y pongan a mi
                        disposición, en la forma que estimen pertinente, la más completa información y oferta de sus
                        productos y servicios; ii) procedan al almacenamiento y tratamiento de mis datos personales y de
                        mi información personal para los objetivos del cumplimiento de este Contrato de Seguro; y iii)
                        hagan efectivos el o los convenios que pudieren estar asociados a los seguros que he contratado.
                        Además, por este acto, autorizo expresamente a que cualquier información, comunicación,
                        declaración o notificación que debe efectuar Southbridge Compañía de Seguros Generales S.A. en
                        virtud del presente contrato de seguros sea realizada a través de correo electrónico o de
                        cualquier sistema de transmisión o registro digital de la palabra escrita o verbal o por
                        cualquier otro medio de comunicación fehaciente. El asegurado declara que conoce, acepta y
                        concuerda que cualquier transacción que se realice en el sitio www.sbseguros.cl con su Rut y
                        clave personal, se entenderá y se reputará, para todos los efectos legales, como realizada por
                        él mismo. En tal sentido, el asegurado se hace entera y totalmente responsable del uso y
                        seguridad de su clave, no cabiéndole Southbridge Compañía de Seguros Generales S.A.. ninguna
                        responsabilidad por el uso indebido de la misma por un tercero distinto del asegurado. El
                        asegurable se hace responsable por la veracidad de la información entregada para la contratación
                        del seguro y del uso de las claves o códigos, liberando a Southbridge Compañía de Seguros
                        Generales S.A. de responsabilidad por cualquier mal uso que hagan terceros de la información que
                        Southbridge Compañía de Seguros Generales S.A. entregue exclusivamente a la persona del
                        asegurable.
                    </v-card-text>
                    <v-card-actions>
                        <v-btn style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #FFB600; border: 2px solid #FFB600;
                    border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
                    letter-spacing: 0.02em; color: white; flex: none; order: 0; flex-grow: 0; margin:auto;"
                            elevation="0" text @click="dialog = false">
                            Aceptar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        <div v-show="showTokuIframe">
            <div id="toku-container"></div>
        </div>

        <div v-if="loading" class="text-center" style="margin-top:20%">
            <v-progress-circular :size="300" color="primary" indeterminate></v-progress-circular>
            <br><br>{{ cont }}
        </div>

        <div v-else>

            <v-form v-if="!showTokuIframe" ref="form" v-model="valid" max-witdh="300px">
                <br>

                <div style="max-width: 500px; margin: auto;">

                    <h3 style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 20px; line-height: 23px;
                  letter-spacing: -0.01em; color: #FFB600; text-transform: uppercase;" class="text-center">
                        ¡{{ userData.name }}, ESTAMOS A UN PASO!</h3>

                    <br>

                    <h4 class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 25px;
                  letter-spacing: -0.01em; color: #2D387C;">Antes de contratar te mostramos un
                        resumen del Seguro de Mascota
                    </h4>

                    <v-row>

                        <v-col>

                            <br>
                            <br>

                            <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mx-auto"
                                style="margin-top:-15%" height="520px" max-width="100%">
                                <v-card-text
                                    class=" font-weight-medium mt-12 text-center text-subtitle-1 color:primary">
                                </v-card-text>

                                <v-col>
                                    <label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 15px; line-height: 25px;
                    letter-spacing: -0.01em; color: #000000;">Contratante: </label><label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 15px; line-height: 25px;
                    letter-spacing: -0.01em; color: #000000; opacity: 70%;">{{ userData.name }}
                                        {{ userData.surname }}</label>
                                    <br>
                                    <br>
                                    <label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 15px; line-height: 25px;
                    letter-spacing: -0.01em; color: #000000;">Rut: </label><label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 15px; line-height: 25px;
                    letter-spacing: -0.01em; color: #000000; opacity: 70%;">{{ userData.rut }}</label>
                                    <br>
                                    <br>
                                    <label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 15px; line-height: 25px;
                    letter-spacing: -0.01em; color: #000000;">Teléfono: </label><label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 15px; line-height: 25px;
                    letter-spacing: -0.01em; color: #000000; opacity: 70%;">{{ userData.phoneNumber }}</label>
                                    <br>
                                    <br>
                                    <label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 15px; line-height: 25px;
                    letter-spacing: -0.01em; color: #000000;">Email: </label><label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 15px; line-height: 25px;
                    letter-spacing: -0.01em; color: #000000; opacity: 70%;">{{ userData.email }}</label>
                                    <br>
                                    <br>
                                    <b style=" font-family: Helvetica; font-style: normal; color: black;">Datos de tu
                                        mascota</b>
                                    <br>
                                    <br>
                                    <label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 15px; line-height: 25px;
                    letter-spacing: -0.01em; color: #000000;">Nombre: </label><label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 15px; line-height: 25px;
                    letter-spacing: -0.01em; color: #000000; opacity: 70%;">{{ petData.petName }}</label>
                                    <br>
                                    <br>
                                    <label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 15px; line-height: 25px;
                    letter-spacing: -0.01em; color: #000000;">Especie: </label><label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 15px; line-height: 25px;
                    letter-spacing: -0.01em; color: #000000; opacity: 70%;">{{ petData.species.name }}</label>
                                    <br>
                                    <br>
                                    <label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 15px; line-height: 25px;
                    letter-spacing: -0.01em; color: #000000;">Raza: </label><label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 15px; line-height: 25px;
                    letter-spacing: -0.01em; color: #000000; opacity: 70%;">{{ petData.breed.name }}</label>
                                    <br>
                                    <br>

                                    <div :class="rectangulo" style="text-align:center;">
                                        <label style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 20px; line-height: 24px; letter-spacing: -0.02em;
                        color: #2D387C;">{{ formatPrice(planData.priceCLP) }}</label> <label
                                            style="font-family: Helvetica; font-style: normal;
                        font-weight: 400; font-size: 14px; line-height: 143.34%; letter-spacing: -0.3px; color: #2D387C; opacity: 0.8;"> / UF {{
                            (planData.price).toFixed(2) }}
                                            mensual
                                        </label>
                                    </div>

                                    <br>
                                    <br>

                                    <h3 class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 12px;
                    line-height: 16px; color: #232323; opacity: 0.75;">*De acuerdo al valor UF del día
                                        {{ fechaActual }}</h3>

                                </v-col>

                            </v-card>

                            <v-hover>
                                <v-checkbox :class="vista" :rules="conditionsRules" required>
                                    <template v-slot:label>
                                        <p :class="terminos">Acepto los <b><a style="color:#2D387C"
                                                    @click="dialog = true">Términos y Condiciones</a></b></p>
                                    </template>
                                </v-checkbox>
                            </v-hover>

                            <br>

                            <div class="button-container">
                                <v-btn
                                    style="background-color: #2D387C; color: white; border-radius: 12px; width: 100%; height: 120%;"
                                    @click="validate()">
                                    Ir a pagar
                                </v-btn>

                                <br>

                                <v-btn v-if="screen > 800" style="margin-left: 40%; margin-top: 2%" text
                                    :to="{ name: 'Step4' }"> Atras </v-btn>

                                <v-btn v-else style="margin-left: 35%; margin-top: 2%" text :to="{ name: 'Step4' }">
                                    Atras
                                </v-btn>

                            </div>

                        </v-col>

                    </v-row>

                </div>

            </v-form>

            <br>
        </div>

    </v-container>
</template>

<script>
import ShieldService from "@/services/shield.service.js";
import NavBar from "@/components/NavBar.vue";
import Titulo from "../components/Titulo.vue";

import CryptoJS from 'crypto-js'

export default {
    components: { NavBar, Titulo },
    data() {
        return {
            secretKey: process.env.VUE_APP_CRYPT,
            terminos: null,
            dialog: false,
            name: "Test",
            fechaActual: null,
            cont: "Cargando.  ",
            loading: false,
            loadingFinal: false,
            loadingToku: false,
            showTokuIframe: false,
            toku: null,
            screen: window.screen.width,
            formatter: new Intl.NumberFormat("es-CL", {
                style: "currency",
                currency: "CLP",
            }),
            userData: {
                name: null,
                surname: null,
                serialNumber: null,
                rut: null,
                region: null,
                commune: null,
                regionCode: null,
                comuneCode: null,
                address: null,
                addressNumber: null,
                addressDepto: null,
                email: null,
                phoneNumber: null,
                couponId: null,
                dates: null,
                day: null,
                month: null,
                year: null,
                stepId: null,
                coverType: null,
            },
            servicesData: {
                stepId: null,
                contactID: null,
                type: 'update',
            },
            petData: {
                species: null,
                breed: null,
                breedCode: null,
                petName: null,
                age: null,
                sex: null,
                hasMicrochip: false,
            },
            planData: {
                couponId: null,
                plan: null,
            },
            conditionsRules: [
                (v) => !!v || "Debe estar de acuerdo con los términos y condiciones",
            ],
            activeCampaingUse: process.env.VUE_APP_ACTIVE_CAMPAIGN ? process.env.VUE_APP_ACTIVE_CAMPAIGN : 'FALSE',
        }
    },
    async mounted() {

        const userDataAux = localStorage.getItem('userData');
        const petDataAux = localStorage.getItem('petData');
        const servicesDataAux = localStorage.getItem('servicesData');
        const planDataAux = localStorage.getItem('planData');

        if (userDataAux) {
            const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
            const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
            this.userData = JSON.parse(decryptedDataUser);
            console.log("userData: ", this.userData);
        }

        if (petDataAux) {
            const bytesPetData = CryptoJS.AES.decrypt(petDataAux, this.secretKey);
            const decryptedDataPet = bytesPetData.toString(CryptoJS.enc.Utf8);
            this.petData = JSON.parse(decryptedDataPet);
            console.log("petData: ", this.petData);
        }

        if (servicesDataAux) {
            const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
            const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
            this.servicesData = JSON.parse(decryptedServicesData);
            console.log("servicesData: ", this.servicesData);
        }

        if (planDataAux) {
            const bytesPlanData = CryptoJS.AES.decrypt(planDataAux, this.secretKey);
            const decryptedDataPlan = bytesPlanData.toString(CryptoJS.enc.Utf8);
            this.planData = JSON.parse(decryptedDataPlan).plan;
            console.log("planData: ", this.planData);
        }

        this.calcularFecha()
        if (this.screen > 800) {
            this.vista = 'test'
            this.rectangulo = 'rectangulo'
            this.terminos = 'terminos'
        } else {
            this.vista = 'test2'
            this.rectangulo = 'rectangulo2'
            this.terminos = 'terminos2'
        }
    },
    methods: {
        calcularFecha() {
            var fecha = new Date().toISOString().substr(0, 10).split('-')
            this.fechaActual = fecha[2] + '-' + fecha[1] + '-' + fecha[0]
        },
        formatPrice(number) {
            return this.formatter.format(number);
        },
        back() {
            this.$emit("back");
        },
        async validate() {
            if (this.$refs.form.validate()) {

                // Conectar con SHIELD
                this.loadingToku = true;
                this.loading = true;
                this.loadingFinal = true;

                try {

                    if (this.activeCampaingUse == 'TRUE') {

                        await ShieldService.updateContactActiveCampain(null, null, null, this.servicesData, 6);
                    }

                    this.cont = "Estamos Procesando tu Solicitud . . ."

                    const res = await ShieldService.createInsurance(
                        this.userData,
                        this.petData,
                        this.planData,
                        this.servicesData.stepId
                    );

                    window.location.href = res;

                    // this.loadingToku = true;

                    // const res = await ShieldService.createInsuranceWithToku(
                    //     this.userData,
                    //     this.petData,
                    //     this.planData,
                    //     this.userData.stepId
                    // );
                    // if (res.personId) this.personId = res.personId

                    this.loadingToku = false;
                    // localStorage.setItem('personIdWaitingForToku', this.personId)
                    // window.location.href = res.redirectUrl
                } catch (error) {
                    this.loadingToku = false;
                    this.loadingFinal = false;
                    this.loading = false;
                    this.$router.push({ path: '/mt/error' })
                }
            }
        },
    }
}
</script>

<style lang="scss">
.terminos {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 143.34%;
    letter-spacing: -0.3px;
    color: #232323;
    opacity: 0.8;
    text-align: justify;
    margin-top: 5%;
}

.terminos2 {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 143.34%;
    letter-spacing: -0.3px;
    color: #232323;
    opacity: 0.8;
    text-align: justify;
    margin-top: 5%;
}

.test {
    margin-left: 25%;
    margin-top: 1%;
}

.test2 {
    margin-left: 15%;
    margin-top: 2%;
}

.v-alert {
    position: fixed;
    left: 50%;
    bottom: 50px;
    transform: translate(-50%, 100%);
    margin: 0 auto;
}

.rectangulo {
    box-sizing: border-box;
    position: absolute;
    width: 231px;
    height: 30px;
    width: 250px;
    border: 2px solid #2D387C1f;
    border-radius: 5px;
    margin-left: 22%;
}

.rectangulo2 {
    box-sizing: border-box;
    position: absolute;
    width: 231px;
    height: 30px;
    width: 250px;
    border: 2px solid #2D387C1f;
    border-radius: 5px;
    margin-left: 10%;
}

.step-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        font-weight: 500;
        text-align: center;
        color: #464e71;
        margin-bottom: 2rem;
    }

    h2 {
        font-weight: 500;
        color: #464e71;
        margin-bottom: 1rem;
    }

    p {
        text-align: center;
    }

    .back-container {
        width: 100%;
        align-items: flex-start;

        .back-button {


            &:hover {
                opacity: 1;
            }
        }
    }

    .button-container {
        text-align: center;
    }

    .forecast-selector {
        text-transform: uppercase;
        font-weight: 600;
        color: #464e71;
    }
}
</style>
