<template>
  <v-container style="margin:auto">

    <NavBar :step="2"></NavBar>

    <Titulo></Titulo>

    <br>
    <br>

    <v-col>
      <v-stepper v-model="e1" elevation="0">
        <v-stepper-header style="box-shadow: none">
          <v-stepper-step :complete="e1 > 1" step="1"> Inicio </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 2" step="2"> Mascota </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 3" step="3"> Plan </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="4"> Tus datos </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="3">

            <div class="step-data">

              <v-form ref="form" v-model="valid" max-witdh="300px" @submit.prevent>

                <h3 class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 15px; letter-spacing: -0.01em;
                    color: #075596;">Estos son nuestros planes</h3>
                <h4 class="text-center"
                  style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 13px; line-height: 16px; color: #232323; opacity: 0.75; padding: 2%;">
                  Selecciona uno de los planes que tenemos para tu mascota</h4>
                <br>

                <v-row>
                  &nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;
                  <v-text-field :class="labelDescuento" v-model="planData.couponId" :rules="couponRules"
                    @blur="deteleSpace()" label="Ingresa aquí tu código de descuento" maxlength="51" solo
                    @keyup.enter="SendCouponID"></v-text-field>

                  <v-btn filled solo
                    style="width:70px; height: 50px; border-radius: 0px 10px 10px 0px; font-family: Helvetica; font-style: normal; font-weight: 700;
                      font-size: 14px; line-height: 143.34%; background-color:#FFB600; color:white; text-transform: capitalize ;"
                    @click="SendCouponID">Aplicar</v-btn>

                </v-row>
              </v-form>

            </div>

            <br v-if="screen < 800">
            <br>

            <div v-if="screen > 800 && loadingPlans == false && tipoPlan == false" class="plan-container">
              <PlanEscritorio v-for=" (plan, indice) in plans" :planSenior="planSenior" :key="plan._id" :planData="plan"
                :planDataAux="planData" :servicesData="servicesData" :index="indice" :tipoPlan="tipoPlan"
                :userData="userData" @next-step="validate" />
            </div>

            <div v-if="screen > 800 && loadingPlans == false && tipoPlan == true" class="plan-container">
              <PlanEscritorio v-for=" (plan, indice) in planSenior" :key="plan._id" :planData="plan" :index="indice"
                :planDataAux="planData" :servicesData="servicesData" :tipoPlan="tipoPlan" :userData="userData"
                @next-step="validate" />
            </div>

            <div v-if="screen <= 800 && loadingPlans == false" class="plan-container">
              <PlanMovil :planData="plans" :planSenior="planSenior" :tipoPlan="tipoPlan" @next-step="validate"
                :planDataAux="planData" :servicesData="servicesData" :loadingPlans="loadingPlans"
                :userData="userData" />
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-row style="justify-content: center; margin-top: 1rem">
        <!-- <v-btn color="primary" @click="validate()"> Siguiente </v-btn> -->
        <v-btn text :to="{ name: 'Step2' }"> Atras </v-btn>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import PlanEscritorio from "@/components/PlanEscritorio.vue";
import PlanMovil from "@/components/PlanMovil.vue";
import NavBar from "@/components/NavBar.vue";
import ShieldService from "../services/shield.service";
import swal from 'sweetalert';
import Titulo from "../components/Titulo.vue";

import CryptoJS from 'crypto-js'

export default {
  name: "PlanForm",
  components: { PlanEscritorio, PlanMovil, NavBar, Titulo },
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,
    plans: [],
    planSenior: [],
    e1: 3,
    loadingPlans: true,
    screen: window.screen.width,
    userData: {
      name: null,
      surname: null,
      serialNumber: null,
      rut: null,
      region: null,
      commune: null,
      regionCode: null,
      comuneCode: null,
      address: null,
      addressNumber: null,
      addressDepto: null,
      email: null,
      phoneNumber: null,
      couponId: null,
      dates: null,
      day: null,
      month: null,
      year: null,
      stepId: null,
      coverType: null,
    },
    servicesData: {
      stepId: null,
      contactID: null,
      type: 'update',
    },
    petData: {
      species: null,
      breed: null,
      breedCode: null,
      petName: null,
      age: null,
      sex: null,
      hasMicrochip: false,
    },
    planData: {
      couponId: null,
      plan: null,
    },
    labelDescuento: '',
    tipoPlan: false,
    couponRules: [
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres",
      (v) => !!v || 'El código de descuento es requerido.',
      (v) => (v && !/\s/.test(v)) || 'El código de descuento no puede contener espacios en blanco.'
    ],
  }),
  async mounted() {

    // this.userData.stepId = localStorage.getItem('stepId')

    const query = this.$route.query;
    const petDataAux = localStorage.getItem('petData');
    const userDataAux = localStorage.getItem('userData');
    const planDataAux = localStorage.getItem('planData');
    const servicesDataAux = localStorage.getItem('servicesData');

    if (query.stepID) {

      const abandoned = await ShieldService.newGetStepAbandoned(query.stepID);

      this.userData = abandoned.userData;
      this.petData = abandoned.petData;
      this.planData = abandoned.planData;
      this.servicesData.stepId = abandoned._id;
      this.servicesData.type = 'update';

      const userDataLocal = JSON.stringify(this.userData);
      const servicesDataLocal = JSON.stringify(this.servicesData);
      const petDataLocal = JSON.stringify(this.petData);
      const planDataLocal = JSON.stringify(this.planData);

      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
      const encryptedPetDataLocal = CryptoJS.AES.encrypt(petDataLocal, this.secretKey).toString();
      const encryptedPlanDataLocal = CryptoJS.AES.encrypt(planDataLocal, this.secretKey).toString();

      localStorage.setItem('userData', encryptedUserDataLocal);
      localStorage.setItem('servicesData', encryptedServicesDataLocal);
      localStorage.setItem('petData', encryptedPetDataLocal);
      localStorage.setItem('planData', encryptedPlanDataLocal);

      // const abandoned = await ShieldService.getAbandoned(query.stepID)

      // const activeCampaingRes = await ShieldService.getContactActiveCampain(abandoned.stepOne[0].email)

      // localStorage.setItem("contactID", activeCampaingRes.data.data.contacts[0].id)
      // localStorage.setItem("stepId", abandoned._id)


      // this.userData.couponId = abandoned.steptwo[0].cuponId
      // localStorage.setItem("cuponId", this.userData.couponId)

      // localStorage.setItem("namepet", abandoned.stepthree[0].petName);

      // localStorage.setItem("breedID", abandoned.stepthree[0].breedId);
      // localStorage.setItem("breedName", abandoned.stepthree[0].breedName);

      // if (abandoned.stepthree[0].species == "Perro") {
      //   localStorage.setItem("species", 'dog');
      // } else if (abandoned.stepthree[0].species == "Gato") {
      //   localStorage.setItem("species", 'cat');
      // }

      // if (abandoned.stepthree[0].sex == 'Macho') {
      //   localStorage.setItem("sex", 'male');
      //   localStorage.setItem("sexLabel", 'Macho');
      //   localStorage.setItem("petSex", 'M');
      // } else if (abandoned.stepthree[0].sex == 'Hembra') {
      //   localStorage.setItem("sex", 'female');
      //   localStorage.setItem("sexLabel", 'Hembra');
      //   localStorage.setItem("petSex", 'H');
      // }

      // localStorage.setItem("ageLabel", abandoned.stepthree[0].petAge);
      // let edad = abandoned.stepthree[0].petAge.split(" ")
      // localStorage.setItem("ageQ", edad[0]);


      // if (edad[1] == "meses") {
      //   localStorage.setItem("ageU", "M");
      //   localStorage.setItem("ageCode", edad[0] + "M");
      // } else if (edad[1] == "años") {
      //   localStorage.setItem("ageU", "Y");
      //   localStorage.setItem("ageCode", edad[0] + "Y");
      // }

      // let email = abandoned.stepOne[0].email
      // let fullName = abandoned.stepOne[0].name;
      // let [firstName, lastName] = fullName.split(' ');

      // localStorage.setItem("name", firstName);
      // localStorage.setItem("surname", lastName);
      // localStorage.setItem("email", email);
    } if (petDataAux) {
      const bytesPetData = CryptoJS.AES.decrypt(petDataAux, this.secretKey);
      const decryptedDataPet = bytesPetData.toString(CryptoJS.enc.Utf8);
      this.petData = JSON.parse(decryptedDataPet);
      console.log("petData: ", this.petData);

      const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
      const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(decryptedDataUser);
      console.log("userData: ", this.userData);

      const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
      const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
      this.servicesData = JSON.parse(decryptedServicesData);
      console.log("servicesData: ", this.servicesData);

      if (planDataAux) {
        const bytesPlanData = CryptoJS.AES.decrypt(planDataAux, this.secretKey);
        const decryptedDataPlan = bytesPlanData.toString(CryptoJS.enc.Utf8);
        this.planData = JSON.parse(decryptedDataPlan);
        console.log("planData: ", this.planData);
      }
    }

    if (this.screen > 800) {
      this.labelDescuento = 'labelDescuento'
    } else {
      this.labelDescuento = 'labelDescuento2'
    }

    // this.petData.age = localStorage.getItem('ageQ')
    // this.petData.unit = localStorage.getItem('ageU')
    this.getPlans();
  },
  methods: {
    deteleSpace() {
      this.planData.couponId = this.planData.couponId.replace(/\s/g, '');
    },
    async SendCouponID() {
      var couponId = this.planData.couponId.trim();
      if (this.$refs.form.validate()) {
        var plansResponse = await ShieldService.getPlans( //Espera el código ingresado en el step 1
          couponId,
          this.petData.age.quantity,
          this.petData.age.unit
        );
        if (plansResponse.length != 0 && plansResponse.status != 'error') {
          swal({
            title: "¡Código válido!",
            text: "El descuento se ha aplicado exitosamente.",
            icon: "success",
            button: "De acuerdo",
          });

          this.plans = plansResponse
          var aux = ['', '', ''];
          this.plans.forEach(plan => {
            if (plan.name === 'Plan Lite') {
              aux[0] = plan
            } else if (plan.name === 'Plan Básico') {
              aux[1] = plan
            } else if (plan.name === 'Plan Plus') {
              aux[2] = plan
            } else {
              this.tipoPlan = true
              this.planSenior[0] = plan
            }
          });
          if (this.planSenior[0]) {
            this.plans = this.planSenior[0]
          } else {
            this.plans = aux
          }
          this.loadingPlans = false;



          this.$forceUpdate();
        } else {
          this.planData.couponId = null
          swal({
            title: "Código no válido",
            text: "Lamentablemente no existen planes asociados a este código de descuento. Intenta con otro código.",
            icon: "info",
            button: "Ok",
          });
        }
      }
    },
    validate(plan) {
      this.$emit("next-step", 2, plan);
    },
    async getPlans() {
      this.loadingPlans = true;
      // this.petData.age = localStorage.getItem('ageQ')
      // this.petData.unit = localStorage.getItem('ageU')
      this.plans = await ShieldService.getPlans(this.planData.couponId, this.petData.age.quantity, this.petData.age.unit);
      var aux = ['', '', ''];
      this.plans.forEach(plan => {
        if (plan.name === 'Plan Lite') {
          aux[0] = plan
        } else if (plan.name === 'Plan Básico') {
          aux[1] = plan
        } else if (plan.name === 'Plan Plus') {
          aux[2] = plan
        } else {
          this.tipoPlan = true
          this.planSenior[0] = plan
        }
      });
      this.plans = aux
      this.loadingPlans = false;
    },
  },
};
</script>


<style lang="scss">
.plan-container {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.labelDescuento {
  width: 320px;
  height: 48px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 10px 0 0 10px;
  margin: auto;
}

.labelDescuento2 {
  min-width: 30%;
  max-width: 62%;
  height: 48px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 10px 0 0 10px;
  margin: auto;
}

.titulo {
  background-image: url("~@/assets/images/titulo2.png");
  max-width: 350px;
  height: 150px;
  margin: auto;
}
</style>